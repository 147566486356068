<template>
  <Breadcrumb />
  <div
    class="accordion mb-3 mt-2"
    :id="String.format('Accordion_{0}', gridSettings.action)"
    ref="accordion"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        :id="String.format('Accordion_{0}_head', gridSettings.action)"
      >
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="
            String.format('#Accordion_{0}_tab1', gridSettings.action)
          "
          aria-expanded="true"
          :aria-controls="
            String.format('Accordion_{0}_tab1', gridSettings.action)
          "
        >
          <p class="text-capitalize fs-5 mb-0">
            {{
              $t(
                "RecordLogs.PageFilter",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </p>
        </button>
      </h2>
      <div
        :id="String.format('Accordion_{0}_tab1', gridSettings.action)"
        class="accordion-collapse collapse show"
        :aria-labelledby="
          String.format('Accordion_{0}_head', gridSettings.action)
        "
        :data-bs-parent="String.format('Accordion_{0}', gridSettings.action)"
      >
        <div class="accordion-body">
          <div class="row align-items-center">
            <div class="col-md-2">
              {{
                $t(
                  "SuperAdmin.Project",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-10">
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :resetSelect="selectsReset"
                @onChange="onChangeCompany"
                :allowEmpty="false"
                :isGatewayRequest="true"
                requestUrl="/Lcdp-SummaryProjects"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              {{
                $t(
                  "SuperAdmin.UserSearch",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-10">
              <input type="text" class="form-control" v-model="query" />
            </div>
          </div>
          <div class="col-md-12 mt-2 text-end">
            <button
              type="button"
              class="btn btn-success btn-history-filter-search me-2"
              @click="onSearch('btn-history-filter-search')"
            >
              <span>
                {{
                  $t(
                    "PermissionGroups.Search",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-history-filter-clear btn-danger"
              @click="onClear"
            >
              {{
                $t(
                  "PermissionGroups.Clear",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="responseError.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in responseError" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :pageNumberStartFrom="0"
    @onRequestFinally="onSearchEnd"
  />
</template>
<script>
import $ from "jquery";
export default {
  name: "SuperAdminUserList",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-history-filter-search, .btn-history-filter-clear",
      exceptionMessage: "",
      actionName: "Lcdp-ListAllUsers",
      selectsReset: false,
      responseError: [],
      companyId: "0",
      query: "",
      gridSettings: {
        routeRequiredParameters: [
          {
            key: "userId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/SuperAdmin/UserDetail/`,

        action: "SuperAdminAllUserList",
        requestUrl: "",

        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        isCommandColumnHide: true,

        autoOrderProcess: true,
      },
      gridColumns: [
        {
          text: this.$t(
            "SuperAdmin.CompanyId",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "companyId",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SuperAdmin.CompanyName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "companyName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SuperAdmin.FirstName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "firstName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SuperAdmin.LastName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lastName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SuperAdmin.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "email",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onChangeCompany(selected) {
      this.companyId = selected.key;
      if (selected.key == null) {
        this.companyId = 0;
      }
    },
    onSearch(buttonName) {
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var searchUrl = this.actionName,
        requestParameters = [];

      if (!String.isNullOrWhiteSpace(this.companyId)) {
        requestParameters.push({ key: "companyId", value: this.companyId });
      }
      if (!String.isNullOrWhiteSpace(this.query)) {
        requestParameters.push({ key: "query", value: this.query });
      }

      this.gridSettings.requestUrl = ""; //trigger watch parameter
      this.gridSettings.requestUrl = String.createGetUrl(
        searchUrl,
        requestParameters
      );
    },
    onSearchEnd() {
      var button = $(String.format(".{0}", "btn-history-filter-search")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      firstSpan.show();
      loadingBlock.hide();
      disabledButtons.prop("disabled", false);
    },
    onClear() {
      this.selectsReset = !this.selectsReset;
      this.query = "";
    },
  },
};
</script>
